import React, {useState, useEffect, useCallback} from 'react';
import ReactPaginate from 'react-paginate';
import { navigate, Link } from "gatsby"
import {formatedDate} from '../modules/date'
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import FadeIn from '../components/FadeIn'
import SlideLeftOnAppear from '../components/SlideLeftOnAppear'

const Actualites = (props) => {

    const [loaded, setloaded] = useState(false)
    
    const [currentPage, setCurrentPage] = useState(props.pageContext.page)

    useEffect(() => {
        if(!loaded && typeof window !== undefined)
        {
            setloaded(true)
        }
        return () => {
        
        }
    }, [])

    useEffect(() => {
        navigate(`/relais-de-lavance-actualites${currentPage === 1 ? '' : '/' + currentPage}`)
        return () => {
        }
    }, [currentPage])

    const makehref = useCallback(
        (page) => {
            console.log(page)
            return '/relais-de-lavance-actualites/' + page
        }
    )

    const onClickPage = useCallback(
        (data) => {
            console.log('click', data.selected)
            console.log('currentPage', currentPage)
            if(data.selected + 1 !== currentPage) {
                setCurrentPage(data.selected + 1)
            }
        }
    )
    console.log(props.pageContext)
    
    return (
        <>
            <Helmet
                title="Actualité du relais de l'avance - Traiteur - Restaurateur"
                meta={[
                    { name: 'description', content: "Le relais de l'avance est un Bar - Restaurant - Traiteur pour mariage et tous événements situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05" },
                    { name: 'og:title', content: "Relais de l'avance Restaurant traiteur mariage Hautes Alpes 05"},
                    { name: 'og:description', content: "Le relais de l'avance est un Bar - restaurant - traiteur pour mariage avec le concept resto'roul situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05"},
                    { name: 'og:type', content: "website"}
                ]}
            />
            
            <Layout activeLink="news">
                <h1 className="site-heading text-center text-white d-block site-heading-lower ml-auto mr-auto">
                    Le relais de l'avance - Notre Actualités 
                </h1>
                {
                    props.pageContext.nodes.length > 0 ? 
                    props.pageContext.nodes.map(node => 
                        
                        <section className="page-section news-heading">
                            <div className="container">
                            {
                                node.imagePrincipale ?
                                    <FadeIn loaded={loaded}>
                                        <img
                                            className="img-fluid rounded news-heading-img mb-3 mb-lg-0 shadow1"
                                            src={node.imagePrincipale.fluid.src}
                                            alt={node.imagePrincipale.description}
                                        />
                                    </FadeIn>
                                :null
                            }
                            
                            <div className="news-heading-content">
                                <div className="row">
                                <div className="col-xl-9 col-lg-10 mx-auto">
                                    <SlideLeftOnAppear loaded={loaded}>
                                    <div className="bg-faded rounded p-5 shadow1">
                                        <h2 className="section-heading mb-4 section-heading-lower">
                                            {node.titre}
                                        </h2>
                                        
                                        <article dangerouslySetInnerHTML={{__html: node.description.childMarkdownRemark.html}}>
                                            
                                        </article>
                                        <p className="text-right">
                                            Lire <Link to={`/relais-de-lavance-actualites/article/${node.url}`}>{node.titre}</Link>
                                        </p>
                                        <p className="text-right blockquote-footer">
                                            Publié le {formatedDate(node.createdAt)}
                                        </p>
                                    </div>
                                    
                                    </SlideLeftOnAppear>
                                </div>
                                </div>
                            </div>
                            
                            </div>
                        </section>
                    )
                    : 
                    <section className="page-section news-heading">
                        <div className="container">
                            <div className="news-heading-content">
                                <div className="row">
                                <div className="col-xl-9 col-lg-10 mx-auto">
                                    <SlideLeftOnAppear loaded={loaded}>
                                    <div className="bg-faded rounded p-5 shadow1">  
                                        <article>
                                            <p className="text-center">Aucun article</p>
                                        </article>
                                    </div>
                                    
                                    </SlideLeftOnAppear>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    initialPage={props.pageContext.page -1 }
                    breakClassName={'break-me'}
                    pageCount={props.pageContext.nbPage}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'page-active'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-item-link'}
                    previousClassName={'previous-next-page'}
                    nextClassName={'previous-next-page'}
                    previousLinkClassName={'previous-link-page'}
                    nextLinkClassName={'next-link-page'}
                    hrefBuilder={makehref}
                    onPageChange={onClickPage}
                />
            </Layout>
        </> 
    )
}

export default Actualites
